body {
  font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  line-height: 1.6;
  overflow-x: hidden;
  font-size: 16px;
  color: #666;
}

body:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

body.offcanvas-menu:before {
  opacity: 1;
  visibility: visible;
}

.text-black {
  color: #000000 !important;
}

.text-secondary {
  color: #ec661f !important;
}

.bg-primary-light {
  background-color: rgba(19, 106, 213, 0.05);
}

.bg-secondary-light {
  background-color: rgba(0, 174, 255, 0.05);
}

a {
  color: #ec661f;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

a:hover {
  color: #ec661f;
  text-decoration: none;
}

::-moz-selection {
  background: #000000;
  color: #ffffff;
}

::selection {
  background: #000000;
  color: #ffffff;
}

.text-primary {
  color: #ec661f !important;
  font-size: 30px;
}

a.text-primary {
  color: #ec661f !important;
}

a.text-primary:hover {
  color: #ec661f !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  color: black;
}

h4 {
  color: #ec661f;
}

h2 {
  color: #ec661f;
}

.btn {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05rem;
}

.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-primary {
  background-color: #ec661f;
  border: #ec661f;
  font-size: 12px;
  /* padding-left: -40px; */
}

.btn-primary {
  color: white;
  font-size: 14px;
}

.btn.btn-primary:hover {
  background: #ec661f;
  border: #ec661f;
  color: white;
}

.btn.btn-secondary {
  background-color: #ec661f;
  border-color: #ec661f;
  font-size: 15px;
}

.btn.btn-secondary:hover {
  background: #ec661f;
}

.btn.btn-sm {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn.btn-outline-primary {
  border-color: #ec661f;
  text-align: center;
  /* margin-left: 70px; */
  margin-bottom: 15px;
  color: #ec661f;
  font-size: 14px;
}

.btn.btn-outline-primary:hover {
  background: #ec661f;
  border-color: #ec661f;
  color: #ffffff;
}

.form-control,
.custom-select {
  border: none;
  font-size: 16px;
  height: 55px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  color: #000000;
}

.form-control:active,
.form-control:focus,
.custom-select:active,
.custom-select:focus {
  color: #000000;
  border-color: #ec661f;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.form-control::-webkit-input-placeholder,
.custom-select::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.5);
}

.form-control::-moz-placeholder,
.custom-select::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.5);
}

.form-control:-ms-input-placeholder,
.custom-select:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.5);
}

.form-control:-moz-placeholder,
.custom-select:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.5);
}

.gutter-v1 {
  margin-right: -20px;
  margin-left: -20px;
}

@media (max-width: 991.98px) {
  .gutter-v1 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.gutter-v1>.col,
.gutter-v1>[class*='col-'] {
  padding-right: 20px;
  padding-left: 20px;
}

@media (max-width: 991.98px) {

  .gutter-v1>.col,
  .gutter-v1>[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.gutter-v2 {
  margin-right: -5px;
  margin-left: -5px;
}

@media (max-width: 991.98px) {
  .gutter-v2 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.gutter-v2>.col,
.gutter-v2>[class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

@media (max-width: 991.98px) {

  .gutter-v2>.col,
  .gutter-v2>[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.gutter-v3 {
  margin-right: -40px;
  margin-left: -40px;
}

@media (max-width: 991.98px) {
  .gutter-v3 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.gutter-v3>.col,
.gutter-v3>[class*='col-'] {
  padding-right: 40px;
  padding-left: 40px;
}

@media (max-width: 991.98px) {

  .gutter-v3>.col,
  .gutter-v3>[class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.spinner-border {
  color: #136ad5;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.top-bar {
  background-color: rgb(186, 186, 186);
  color: black;
}

/* .top-bar a {
  color: #ffffff;
} */
.logo {
  color: #ffffff;
}

.logo img {
  width: 300px;
  margin-left: -80px;
}

/* @media (max-width: 991.98px) {
  .logo {
    left: 120px;
  }
}
@media (max-width: 530px) {
  .logo {
    left: 320px;
  }
}*/
@media (max-width: 400px) {
  .logo img {
    width: 190px;
    margin-right: -90px;
    /* margin-top: 10px; */
  }
}

.logo:hover {
  color: #ffffff;
}

.menu-absolute {
  position: absolute !important;
  /* padding-top: 8px; */
}

.btn-enroll {
  font-size: 10px;
}

.btn-enroll .btn-book {
  position: absolute;
  /* right: 5px; */
  background-color: #ec661f;
  /* background-color: transparent; */
  color: white;
  border-radius: 22px;
  width: 140px;
  height: 40px;
  margin-top: -14px;
  font-size: 12px;
  padding-top: 10px;
}

@media (max-width: 991.98px) {
  .btn-enroll .btn-book {
    display: none;
  }
}

/* .btn-book:hover{
  color: #fff;
 } */
@media (max-width: 991.98px) {
  .btn-book {
    right: 60px;
  }
}

.site-nav {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0;
}

.site-nav a {
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.site-nav {
  padding-top: 0px;
  padding-bottom: 0px;
}

.site-nav h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.site-nav .site-navigation .site-menu {
  background: white;
  width: 100%;
  justify-content: center;
  /* margin-top: 1px; */
  margin-top: -1px;
  height: 80px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.site-nav .site-navigation .site-menu>li {
  display: inline-block;
}

.site-nav .site-navigation .site-menu>li>a {
  font-size: 18px;
  padding: 10px 10px;
  display: inline-block;
  text-decoration: none !important;
  color: black;
  position: relative;
  margin: 3px 0px;
  border-radius: 5px;
  font-weight: 550;
  /*  */
}

.site-nav .site-navigation .site-menu>li>a:hover {
  background-color: #ec661f;
  border-radius: 25px;
  color: white !important;
}

/* .site-nav .site-navigation .site-menu > li > a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ec661f;
  color: #ec661f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.site-nav .site-navigation .site-menu > li > a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  color: #ec661f;
} */
.site-nav .site-navigation .site-menu>li.cta-button a {
  padding: 5px 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000000;
  border-radius: 30px;
}

.site-nav .site-navigation .site-menu>li.cta-button a:hover {
  color: #ffffff;
  background: #ffffff;
  border-color: #ffffff;
}

.site-nav .site-navigation .site-menu>li.cta-button.active a {
  color: #000000;
  background: #ffffff;
  border-color: #ffffff;
}

.site-nav .site-navigation .site-menu>li.active>a {
  color: #ffffff;
}

.site-nav .site-navigation .site-menu .has-children {
  position: relative;
}

.site-nav .site-navigation .site-menu .has-children>a {
  position: relative;
  padding-right: 20px;
}

.site-nav .site-navigation .site-menu .has-children>a:before {
  position: absolute;
  content: '\e313';
  font-size: 14px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'icomoon';
}

.site-nav .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  z-index: 999;
  position: absolute;
  text-align: left;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #ffffff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;

  width: 300px !important;
}

.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  display: none;
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-nav .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
  display: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}

.site-nav .site-navigation .site-menu .has-children .dropdown a {
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #000000;
}

.site-nav .site-navigation .site-menu .has-children .dropdown .active {
  color: #ec661f !important;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 180px;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li>a {
  padding: 5px 20px;
  display: block;
  font-size: 17px;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li>a:hover {
  background-color: #ec661f;
  color: white;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li.active>a {
  color: #ec661f;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
  content: '\e315';
  right: 20px;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
  left: 100%;
  top: 0;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown li a:hover,
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children>ul li a:hover {
  color: #ec661f;
}

.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
.site-nav .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
  color: #136ad5;
}

.site-nav .site-navigation .site-menu .has-children:hover>a,
.site-nav .site-navigation .site-menu .has-children:focus>a,
.site-nav .site-navigation .site-menu .has-children:active>a {
  color: black;
}

.site-nav .site-navigation .site-menu .has-children:hover,
.site-nav .site-navigation .site-menu .has-children:focus,
.site-nav .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-nav .site-navigation .site-menu .has-children:hover>.dropdown,
.site-nav .site-navigation .site-menu .has-children:focus>.dropdown,
.site-nav .site-navigation .site-menu .has-children:active>.dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-nav.dark a {
  color: #000000;
}

.site-nav.dark .site-navigation .site-menu>li>a {
  color: rgba(0, 0, 0, 0.5);
}

.site-nav.dark .site-navigation .site-menu>li>a:hover {
  color: #000000;
}

.site-nav.dark .site-navigation .site-menu>li.active>a {
  color: #000000;
}

.site-nav.dark .site-navigation .site-menu>li.has-children.dropdown:hover>a,
.site-nav.dark .site-navigation .site-menu>li.has-children.dropdown:focus>a,
.site-nav.dark .site-navigation .site-menu>li.has-children.dropdown:active>a {
  color: #ec661f;
}

.site-nav.dark .site-navigation .site-menu>li.has-children:hover>a,
.site-nav.dark .site-navigation .site-menu>li.has-children:focus>a,
.site-nav.dark .site-navigation .site-menu>li.has-children:active>a {
  color: #000000;
}

.site-mobile-menu-close {
  display: block;
  position: relative;
  height: 30px;
  width: 30px;
  z-index: 99;
  cursor: pointer;
  top: -20px;
}

.site-mobile-menu-close>span {
  cursor: pointer;
  display: block;
  position: absolute;
  height: 30px;
  width: 30px;
}

.site-mobile-menu-close>span:before,
.site-mobile-menu-close>span:after {
  position: absolute;
  content: '';
  width: 2px;
  height: 30px;
  background: #000000;
}

.site-mobile-menu-close>span:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.site-mobile-menu-close>span:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #ffffff;
  height: calc(100vh);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
  transition: 0.8s all cubic-bezier(0.23, 1, 0.32, 1);
}

.offcanvas-menu .site-mobile-menu {
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  /* margin-left: 0px; */
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 5px 20px;
  display: block;
  position: relative;
  color: #000000;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #ec661f;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap .welcomeUser {
  position: relative;
  display: block;

  margin-top: 230px;
  margin-right: 140px;
}

.site-mobile-menu .site-nav-wrap li i {
  color: #ec661f;
  position: absolute;

  top: 10px !important;
}

.site-mobile-menu .site-nav-wrap li.active>a {
  color: #ec661f;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 14px;
  z-index: 20;
  font-family: 'icomoon';
  content: '\e313';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap>li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap>li>a {
  padding-left: 20px;
  font-size: 14px;
}

.site-mobile-menu .site-nav-wrap>li>ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>a {
  padding-left: 40px;
  font-size: 14px;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
  font-size: 14px;
  padding-left: 60px;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.sticky-wrapper .site-navbar .site-menu-toggle {
  color: #000000;
}

.sticky-wrapper .site-navbar .site-logo a {
  color: black;
  position: fixed;
}

.sticky-wrapper .site-navbar .site-menu>li>a {
  color: rgba(0, 0, 0, 0.8) !important;
}

.sticky-wrapper .site-navbar .site-menu>li>a:hover,
.sticky-wrapper .site-navbar .site-menu>li>a.active {
  color: #000000 !important;
}

.sticky-wrapper.is-sticky .burger:before,
.sticky-wrapper.is-sticky .burger span,
.sticky-wrapper.is-sticky .burger:after {
  background: #000000;
  -webkit-transition: 0s all ease;
  -o-transition: 0s all ease;
  transition: 0s all ease;
}

.sticky-wrapper.is-sticky .site-navbar {
  background: #000000;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
}

.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: #000000 !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu>li>a {
  color: #000000 !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu>li>a:hover,
.sticky-wrapper.is-sticky .site-navbar .site-menu>li>a.active {
  color: #ec661f !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu>li.active a {
  color: #ec661f !important;
}

.sticky-wrapper .shrink {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sticky-nav {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background: transparent;
}

#sticky-wrapper.is-sticky .sticky-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  height: 90px;
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  margin-top: -15px;
}

#sticky-wrapper.is-sticky .sticky-nav .burger:before,
#sticky-wrapper.is-sticky .sticky-nav .burger span,
#sticky-wrapper.is-sticky .sticky-nav .burger:after {
  background: #000000;
  -webkit-transition: 0s all ease;
  -o-transition: 0s all ease;
  transition: 0s all ease;
}

#sticky-wrapper.is-sticky .sticky-nav .logo {
  color: gray !important;
}

#sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a {
  color: black !important;
}

#sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a:hover,
#sticky-wrapper.is-sticky .sticky-nav .site-menu>li>a.active {
  background-color: #ec661f !important;
  color: white !important;
}

#sticky-wrapper.is-sticky .sticky-nav .site-menu>li.active a {
  color: #ec661f !important;
}

/* Burger */
.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-top: -50px;
}

.burger.light:before,
.burger.light span,
.burger.light:after {
  background: #ffffff;
}

.burger:before,
.burger span,
.burger:after {
  width: 100%;
  height: 2px;
  color: white;
  display: block;
  background: white;
  border-radius: 2px;
  position: absolute;
  opacity: 1;
}

.burger:before,
.burger:after {
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: '';
}

.burger:before {
  top: 4px;
}

.burger span {
  top: 15px;
}

.burger:after {
  top: 26px;
}

/* Hover */
.burger:hover:before {
  top: 7px;
}

.burger:hover:after {
  top: 23px;
}

/* Click */
.burger.active span {
  opacity: 0;
}

.burger.active:before,
.burger.active:after {
  top: 40%;
}

.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
  /*for IE*/
}

.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
  /*for IE*/
}

.burger:focus {
  outline: none;
}

@media (max-width: 400px) {
  .burger {
    margin-top: 10px;
  }
}

.top-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: right;
  background-color: #323232;
}

.untree_co-hero {
  top: 70px;
}

.untree_co-hero,
.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.untree_co-hero.overlay,
.bg-img.overlay {
  position: relative;
}

.untree_co-hero.overlay:before,
.bg-img.overlay:before {
  position: absolute;
  content: '';
  top: -50px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4b6bc6;
  z-index: -0;
  box-shadow: rgba(26, 26, 32, 0.1) 0px 4px 16px,
    rgba(29, 29, 31, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  height: 100px;
  justify-content: center;

  width: 100%;
}

@media (max-width: 400px) {

  .untree_co-hero.overlay:before,
  .bg-img.overlay:before {
    left: 0;
  }
}

.untree_co-hero>.container,
.bg-img>.container {
  position: relative;
  z-index: 2;
}

.untree_co-hero,
.untree_co-hero>.container>.row,
.bg-img,
.bg-img>.container>.row {
  /* height: 80vh;
  min-height: 150px; */
}

@media (max-width: 767.98px) {

  .untree_co-hero,
  .untree_co-hero>.container>.row,
  .bg-img,
  .bg-img>.container>.row {
    min-height: 350px;
  }
}

@media (max-width: 400px) {

  .untree_co-hero,
  .untree_co-hero>.container>.row,
  .bg-img,
  .bg-img>.container>.row {
    min-height: 250px;
  }
}

.untree_co-hero.inner-page,
.untree_co-hero.inner-page>.container>.row,
.bg-img.inner-page,
.bg-img.inner-page>.container>.row {
  height: 40vh;
  min-height: 150px;
}

@media (max-width: 767.98px) {

  .untree_co-hero.inner-page,
  .untree_co-hero.inner-page>.container>.row,
  .bg-img.inner-page,
  .bg-img.inner-page>.container>.row {
    min-height: 350px;
  }
}

.untree_co-hero .caption,
.bg-img .caption {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.untree_co-hero .heading,
.bg-img .heading {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.4;
}

.untree_co-hero .heading {
  font-size: 30px;
  margin-top: -20px;
}

@media (max-width: 991.98px) {

  .untree_co-hero .heading,
  .bg-img .heading {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .untree_co-hero .heading {
    font-size: 20px;
    height: auto;
    /* margin-top: 90px; */
  }

  .untree_co-hero.overlay:before,
  .bg-img.overlay:before {
    height: 140px !important;
  }
}

@media (max-width: 1920px) {
  .untree_co-hero .heading {
    padding-bottom: 250px;
  }
}

.untree_co-hero .link-highlight,
.bg-img .link-highlight {
  color: #ffffff;
  position: relative;
  display: inline;
  -webkit-box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.untree_co-hero .link-highlight:hover,
.bg-img .link-highlight:hover {
  -webkit-box-shadow: 0 2px 0 0 white;
  box-shadow: 0 2px 0 0 white;
}

.untree_co-hero .desc,
.bg-img .desc {
  font-size: 18px;
  max-width: 450px;
}

@media (max-width: 991.98px) {

  .untree_co-hero .desc,
  .bg-img .desc {
    margin: 0 auto;
  }
}

.untree_co-hero .cta-section,
.untree_co-hero .cta-section>.container>.row,
.bg-img .cta-section,
.bg-img .cta-section>.container>.row {
  height: 70vh;
  min-height: 450px;
}

.untree_co-section {
  padding: 70px 0;
  /* margin-top: -30px; */
  position: relative;
}

.untree_co-section h2 {
  font-size: 34px;
  color: #ec661f;
}

.untree_co-section h5 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

@media (max-width: 400px) {
  .untree_co-section h3 {
    font-size: 18px;
  }

  .untree_co-section p {
    font-size: 16px;
  }

  .untree_co-section h2 {
    font-size: 22px;
  }

  .untree_co-section h5 {
    font-size: 16px;
  }

  .untree_co-section h1 {
    font-size: 26px;
    text-align: justify;
  }
}

@media (max-width: 991.98px) {
  .untree_co-section {
    padding: 50px 0;
  }
}

.untree_co-section .heading h3 {
  font-size: 35px;
  color: #000000;
}

@media (max-width: 991.98px) {
  .untree_co-section .heading {
    font-size: 20px;
  }
}

.untree_co-section .heading strong {
  font-weight: 700;
}

.position-relative {
  position: relative;
}

.block-v1 {
  position: relative;
  z-index: 8;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .block-v1 {
    margin-bottom: 70px;
  }
}

@media (max-width: 767.98px) {
  .block-v1 {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.block-v1 [class^='icon-'] {
  font-size: 35px;
  color: #ffffff;
  margin-bottom: 30px;
  display: block;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.block-v1 h3 {
  font-size: 20px;
  color: #ffffff;
}

.block-v1 p {
  color: rgba(255, 255, 255, 0.7);
}

.block-v1 *:last-child {
  margin-bottom: 0;
}

.block-v1.color-1 {
  background-color: #5cb100;
}

.block-v1.color-2 {
  background-color: #ec661f;
}

.block-v1.color-3 {
  background-color: #4fb0dc;
}

.feature {
  /* margin-bottom: 20px; */
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 20px 25px 35px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: -20px;
}

.feature [class^='flaticon-'],
.feature [class^='icon-'],
.feature [class^='uil uil-'] {
  position: relative;
  /* margin-bottom: 30px; */
  font-size: 30px;
  color: #ec661f;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}

.feature [class^='flaticon-']:after,
.feature [class^='icon-']:after,
.feature [class^='uil uil-']:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: -5px;
  top: -5px;
  /* border-radius: 50%; */
  /* border: 1px solid rgba(19, 106, 213, 0.2); */
}

.feature [class^='flaticon-'].color-1,
.feature [class^='icon-'].color-1,
.feature [class^='uil uil-'].color-1 {
  background: rgba(92, 177, 0, 0.1);
  color: #5cb100;
}

.feature [class^='flaticon-'].color-1:after,
.feature [class^='icon-'].color-1:after,
.feature [class^='uil uil-'].color-1:after {
  border: 1px solid rgba(92, 177, 0, 0.2);
}

.feature [class^='flaticon-'].color-2,
.feature [class^='icon-'].color-2,
.feature [class^='uil uil-'].color-2 {
  background: rgba(236, 102, 31, 0.1);
  color: #ec661f;
}

.feature [class^='flaticon-'].color-2:after,
.feature [class^='icon-'].color-2:after,
.feature [class^='uil uil-'].color-2:after {
  border: 1px solid rgba(236, 102, 31, 0.2);
}

.feature [class^='flaticon-'].color-3,
.feature [class^='icon-'].color-3,
.feature [class^='uil uil-'].color-3 {
  background: rgba(79, 176, 220, 0.1);
  color: #4fb0dc;
}

.feature [class^='flaticon-'].color-3:after,
.feature [class^='icon-'].color-3:after,
.feature [class^='uil uil-'].color-3:after {
  border: 1px solid rgba(79, 176, 220, 0.2);
}

.feature [class^='flaticon-'].color-4,
.feature [class^='icon-'].color-4,
.feature [class^='uil uil-'].color-4 {
  background: rgba(107, 83, 171, 0.1);
  color: #6b53ab;
}

.feature [class^='flaticon-'].color-4:after,
.feature [class^='icon-'].color-4:after,
.feature [class^='uil uil-'].color-4:after {
  border: 1px solid rgba(107, 83, 171, 0.2);
}

.feature [class^='flaticon-'].color-5,
.feature [class^='icon-'].color-5,
.feature [class^='uil uil-'].color-5 {
  background: rgba(19, 106, 213, 0.1);
  color: #ec661f;
}

.feature [class^='flaticon-'].color-5:after,
.feature [class^='icon-'].color-5:after,
.feature [class^='uil uil-'].color-5:after {
  border: 1px solid rgba(19, 106, 213, 0.2);
}

.feature [class^='flaticon-'].color-6,
.feature [class^='icon-'].color-6,
.feature [class^='uil uil-'].color-6 {
  background: rgba(253, 152, 0, 0.1);
  color: #ec661f;
}

.feature [class^='flaticon-'].color-6:after,
.feature [class^='icon-'].color-6:after,
.feature [class^='uil uil-'].color-6:after {
  border: 1px solid rgba(253, 152, 0, 0.2);
}

.feature h3 {
  font-size: 20px;
  color: #ec661f;
}

.feature h4 {
  color: black !important;
  width: 100%;
  font-size: 23px;
}

.feature p {
  font-size: 20px;
  color: black !important;
  text-align: left;
}

.feature h6 {
  color: black;
}

@media (max-width: 767px) {
  .feature p {
    text-align: justify;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .feature p {
    text-align: justify;
    font-size: 18px;
  }

  .feature .btn-primary {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .feature {
    padding: 25px 5px;
  }

  .feature h4 {
    font-size: 14px;
  }

  .feature .btn-primary {
    text-align: center;
    margin-bottom: 10px;
  }

  .feature p {
    text-align: left;
    font-size: 16px;
  }
}

.featureHome {
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  background-color: white;
}

.featureHome [class^='flaticon-'],
.featureHome [class^='icon-'],
.featureHome [class^='uil uil-'] {
  position: relative;
  /* margin-bottom: 30px; */
  font-size: 30px;
  color: #ec661f;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}

.featureHome [class^='flaticon-']:after,
.featureHome [class^='icon-']:after,
.featureHome [class^='uil uil-']:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: -5px;
  top: -5px;
  /* border-radius: 50%; */
  /* border: 1px solid rgba(19, 106, 213, 0.2); */
}

.featureHome [class^='flaticon-'].color-1,
.featureHome [class^='icon-'].color-1,
.featureHome [class^='uil uil-'].color-1 {
  background: rgba(92, 177, 0, 0.1);
  color: #5cb100;
}

.featureHome [class^='flaticon-'].color-1:after,
.featureHome [class^='icon-'].color-1:after,
.featureHome [class^='uil uil-'].color-1:after {
  border: 1px solid rgba(92, 177, 0, 0.2);
}

.featureHome [class^='flaticon-'].color-2,
.featureHome [class^='icon-'].color-2,
.featureHome [class^='uil uil-'].color-2 {
  background: rgba(236, 102, 31, 0.1);
  color: #ec661f;
}

.featureHome [class^='flaticon-'].color-2:after,
.featureHome [class^='icon-'].color-2:after,
.featureHome [class^='uil uil-'].color-2:after {
  border: 1px solid rgba(236, 102, 31, 0.2);
}

.featureHome [class^='flaticon-'].color-3,
.featureHome [class^='icon-'].color-3,
.featureHome [class^='uil uil-'].color-3 {
  background: rgba(79, 176, 220, 0.1);
  color: #4fb0dc;
}

.featureHome [class^='flaticon-'].color-3:after,
.featureHome [class^='icon-'].color-3:after,
.featureHome [class^='uil uil-'].color-3:after {
  border: 1px solid rgba(79, 176, 220, 0.2);
}

.featureHome [class^='flaticon-'].color-4,
.featureHome [class^='icon-'].color-4,
.featureHome [class^='uil uil-'].color-4 {
  background: rgba(107, 83, 171, 0.1);
  color: #6b53ab;
}

.featureHome [class^='flaticon-'].color-4:after,
.featureHome [class^='icon-'].color-4:after,
.featureHome [class^='uil uil-'].color-4:after {
  border: 1px solid rgba(107, 83, 171, 0.2);
}

.featureHome [class^='flaticon-'].color-5,
.featureHome [class^='icon-'].color-5,
.featureHome [class^='uil uil-'].color-5 {
  background: rgba(19, 106, 213, 0.1);
  color: #ec661f;
}

.featureHome[class^='flaticon-'].color-5:after,
.featureHome [class^='icon-'].color-5:after,
.featureHome [class^='uil uil-'].color-5:after {
  border: 1px solid rgba(19, 106, 213, 0.2);
}

.featureHome [class^='flaticon-'].color-6,
.featureHome [class^='icon-'].color-6,
.featureHome [class^='uil uil-'].color-6 {
  background: rgba(253, 152, 0, 0.1);
  color: #ec661f;
}

.featureHome [class^='flaticon-'].color-6:after,
.featureHome [class^='icon-'].color-6:after,
.featureHome [class^='uil uil-'].color-6:after {
  border: 1px solid rgba(253, 152, 0, 0.2);
}

.featureHome h3 {
  font-size: 20px;
  color: #ec661f;
}

.featureHome h4 {
  color: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  font-size: 23px;
}

.featureHome p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5) !important;
  text-align: left;
}

@media (max-width: 767px) {
  .featureHome p {
    text-align: justify;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .featureHome p {
    text-align: justify;
    font-size: 18px;
  }

  .featureHome .btn-primary {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .featureHome {
    padding: 25px 5px;
  }

  .featureHome .btn-primary {
    text-align: center;
    margin-bottom: 10px;
  }

  .featureHome p {
    text-align: left;
    font-size: 16px;
  }
}

.bg-1:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 70%;
  right: 10%;
  top: 15px;
  z-index: -1;
  background: rgba(226, 117, 66, 0.37);
}

@media (max-width: 400px) {
  .bg-1 {
    display: none;
  }
}

.bg-2:before {
  content: '';
  position: absolute;
  height: 40%;
  width: 70%;
  left: -50%;
  top: 0px;
  z-index: -1;
  background: rgba(236, 102, 31, 0.1);
}

@media (max-width: 991.98px) {
  .bg-2 {
    display: none;
  }
}

.line-bottom {
  position: relative;
  padding-bottom: 0px;
  color: #ec661f;
  text-align: center;
}

.line-bottom:before {
  display: none;
  content: '';
  position: absolute;
  width: 40px;
  height: 3px;
  background: #ec661f;
  bottom: 0;
}

.line-bottom.text-center:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.media-h {
  /* margin-bottom: 10px; */
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 20px 15px 30px 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-radius: 15px;
  text-align: center;
  height: 270px;
}

.media-h figure {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 170px;
  flex: 0 0 170px;
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #fecda6;
}

.media-h figure h1 {
  margin-top: 20px;
  font-size: 54px;
}

.media-h figure img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  height: 100%;
  margin-bottom: 0;
  width: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
}

.media-h .media-h-body {
  padding: 15px;

  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}

.media-h .media-h-body h2 {
  font-size: 22px;
}

.media-h-body h5 {
  font-size: 17px;
  color: #7b7b7b;
}

.media-h:hover {
  border: 2px solid #ec661f;
}

/* .media-h:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
} */
/* Home */
.media-h-body .rounded {
  /* border-radius: 20px; */
  border-top: 2px solid #ec661f;
}

@media (max-width: 400px) {
  .media-h-body {
    margin-top: 10px;
  }

  .media-h-body h2 {
    font-size: 10px;
  }

  .media-h-body h5 {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .media-h {
    height: auto;
  }
}

.meta {
  font-size: 14px;
  color: #ccc;
}

.video-play-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  border-radius: 50%;
  background: #ec661f;
  color: #ffffff;
  display: inline-block;
}

.video-play-btn:before {
  width: 90px;
  height: 90px;
  border: 2px solid #136ad5;
  position: absolute;
  content: '';
  border-radius: 50%;
  left: -5px;
  top: -5px;
}

.video-play-btn span {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  -webkit-transform: translate(-40%, -50%);
  -ms-transform: translate(-40%, -50%);
  transform: translate(-40%, -50%);
}

.video-play-btn:hover span {
  color: #ffffff;
}

.block-testimonial p {
  font-size: 18px;
  color: #000000;
  line-height: 2;
}

.block-testimonial .author {
  display: block;
}

.block-testimonial .author img {
  margin: 0 auto;
  width: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.block-testimonial .author h3 {
  font-size: 18px;
  color: #ec661f;
  margin-bottom: 0;
}

.block-testimonial .author .position {
  font-size: 14px;
}

.wide-slider-testimonial .owl-dots {
  position: absolute;
  display: inline-block;
  width: auto;
}

.accordion-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
}

.instagram-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -8px;
  margin-left: -8px;
}

.instagram-gallery li {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
  padding-left: 8px;
  padding-bottom: 8px;
}

.count-numbers .counter {
  font-size: 26px;
  font-family: 'Display Playfair', serif;
  color: #ec661f;
}

.pricing {
  text-align: center;
  /* padding: 40px; */
  border: 1px solid #ec661f;
  border-radius: 4px;
  height: 95%;
}

/* .pricing-body {
  background-color: #00aeff;
} */
.pricing .pricing-plan-title {
  color: white;
  background-color: #ec661f;
  text-align: right;
  padding: 4px 16px 4px 0;
  margin: 0;
}

@media (max-width: 991.98px) {
  .pricing {
    /* padding: 40px 25px; */
  }
}

.pricing .pricing-img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.pricing .pricing-img:before {
  position: absolute;
  content: '';
  background: rgba(19, 106, 213, 0.2);
  z-index: -1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.pricing .pricing-img img {
  height: 90px;
}

.pricing .pricing-body .price {
  margin-bottom: 20px;
}

.pricing .pricing-body .price .fig {
  color: #ec661f;
  font-size: 40px;
}

/*  */
.pricing .pricing-body p {
  text-align: center;
}

.pricing .timing {
  font-size: 40px;
}

.pricing .priceG {
  margin-bottom: 10px;
}

.pricingG {
  border: 1px solid rgb(201, 199, 199);
  border-radius: 20px;
  height: 200px;
}

.staff {
  padding: 20px 10px;
}

.staff img {
  border-radius: 30px;
  width: 200px;
  height: 180px;
}

.staff h3 {
  font-size: 20px;
  color: #ec661f;
}

.staff .position {
  color: rgba(52, 58, 64, 0.5);
}

.custom-pagination li {
  display: inline-block;
}

.custom-pagination li a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
}

.custom-pagination li.active>a {
  background: #fd9800;
  color: #ffffff;
  border-radius: 50%;
}

.contact-info i {
  font-size: 20px;
  float: left;
  width: 44px;
  height: 44px;
  background: #ec661f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
}

.contact-info h4 {
  font-size: 18px;
  padding: 0 0 0 60px;
}

.contact-info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
}

.item {
  border: none;
  margin-bottom: 30px;
  border-radius: 4px;
  display: block;
  height: auto;
}

.item a {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  display: block;
}

.item a img {
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.item .item-wrap {
  display: block;
  position: relative;
}

.item .item-wrap:after {
  z-index: 2;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.item .item-wrap>span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  color: #ffffff;
  font-size: 1.7rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.item .item-wrap:hover:after {
  opacity: 1;
  visibility: visible;
}

.item .item-wrap:hover span {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

.item:hover a img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.category {
  display: inline-block;
  padding: 20px;
  background: white;
  border-radius: 4px;
  text-decoration: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.category,
.category h3,
.category span {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.category h3 {
  margin: 0;
  font-size: 22px;
  line-height: 1;
  color: #343a40;
  padding-top: 28px;
  padding-left: 25px;
  font-weight: 400;
}

.category span {
  font-size: 13px;
  color: rgba(52, 58, 64, 0.4);
}

.category .uil {
  text-align: center;
  display: inline-block;
  color: #ec661f;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  /* margin-right: 20px; */
  margin-top: 19px;
  background: #ffffff;
  font-size: 30px;
  line-height: 40px;
}

.category:hover {
  background: #ec661f;
  color: white;
}

.category:hover h3,
.category:hover span {
  color: #ffffff;
}

.category:hover span {
  color: rgba(255, 255, 255, 0.5);
}

.section-title .heading {
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .section-title .heading {
    font-size: 20px;
  }
}

.services-section {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  /* width: 100%; */
  -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.img-wrap-2 {
  margin-top: 20px;
  position: relative;
  z-index: 9;
}

.img-wrap-2:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.img-wrap-2 .dotted:before {
  position: absolute;
  content: '';
  /* background-image: url('../../public/images/dollar.png'); */
  height: 273px;
  width: 313px;
  z-index: -1;
  bottom: -50px;
  left: -50px;
}

@media (max-width: 400px) {
  .img-wrap-2 {
    margin-top: 20px;
  }
}

.custom-media {
  background: #ffffff;
  -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
}

.custom-media h3 {
  font-size: 18px;
  color: #000000;
}

.custom-media p {
  color: rgba(0, 0, 0, 0.4);
}

.custom-media .review {
  color: #ec661f;
}

.custom-media .custom-media-body {
  padding: 30px;
}

.custom-media .custom-media-body .price {
  font-size: 22px;
  color: #343a40;
}

.form-box {
  background: #ffffff;
  padding: 40px;
  margin-top: -110px;
  border-radius: 7px;
  /* margin-left: -80px; */
  -webkit-box-shadow: 0 19px 35px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .form-box {
    margin-top: 30px;
    margin-left: 10px;
  }
}

/*  */
.form-groupRegister {
  background: #ffffff;
  padding: 40px;
  /* margin-top: 10px; */
  border-radius: 7px;
  -webkit-box-shadow: 0 19px 35px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .form-groupRegister {
    margin-top: 30px;
  }
}

.custom-block {
  margin-bottom: 30px;
}

.custom-block .section-title {
  font-size: 25px;
  font-weight: 700;
  color: #ec661f;
}

.custom-block img {
  width: 100%;
}

.custom-accordion .accordion-item {
  margin-bottom: 0px;
  position: relative;
  border-radius: 0px;
  overflow: hidden;
}

.custom-accordion .accordion-item .btn-link {
  display: block;
  width: 100%;
  padding: 15px 0;
  text-decoration: none;
  text-align: left;
  color: #000000;
  background: transparent;
  border: none;
  padding-left: 40px;
  border-radius: 0;
  position: relative;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-weight: normal !important;
  font-family: 'Inter', sans-serif;
  font-size: 16px !important;
}

.custom-accordion .accordion-item .btn-link:before {
  font-family: 'icomoon';
  content: '\f067';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
}

.custom-accordion .accordion-item .btn-link[aria-expanded='true'] {
  font-weight: 700;
  color: #ffffff !important;
  background: #ec661f;
}

.custom-accordion .accordion-item .btn-link[aria-expanded='true']:before {
  font-family: 'icomoon';
  content: '\f068';
  position: absolute;
  color: #ffffff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
}

.custom-accordion .accordion-item.active {
  z-index: 2;
}

.custom-accordion .accordion-item.active .btn-link {
  color: #000000;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.custom-accordion .accordion-item .accordion-body {
  padding: 20px 20px 20px 20px;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}

.control .caption {
  position: relative;
  top: -3px;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 4px;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
  background: #ccc;
}

.control input:checked~.control__indicator {
  background: #136ad5;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
  background: #1776ea;
}

.control input:disabled~.control__indicator {
  background: #e6e6e6;
  opacity: 0.9;
  pointer-events: none;
}

.control__indicator:after {
  font-family: 'icomoon';
  content: '\e5ca';
  position: absolute;
  display: none;
  font-size: 14px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.control input:checked~.control__indicator:after {
  display: block;
  color: #fff;
}

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}

.control--checkbox input:disabled:checked~.control__indicator {
  background-color: #ec661f;
  opacity: 0.2;
}

.gal-item {
  display: block;
  margin-bottom: 8px;
}

.gal-item img {
  border-radius: 4px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  opacity: 1;
}

.gal-item:hover img {
  opacity: 0.5;
}

.social-icons li {
  display: inline-block;
}

.social-icons li a {
  color: #ec661f;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: white;
  border-color: #ec661f;
}

.social-icons li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.social-icons li a:hover {
  color: #ffffff;
  background-color: #ec661f;
}

.social-icons li:first-child a {
  padding-left: 0;
}

.social-icons.light li a {
  color: #000000;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background: #f8f9fa;
}

.video-wrap {
  position: relative;
  display: block;
}

.video-wrap .play-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.video-wrap .play-wrap>span {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-wrap:hover .play-wrap {
  width: 60px;
  height: 60px;
}

.owl-single.dots-absolute .owl-dots,
.owl-carousel.dots-absolute .owl-dots {
  bottom: 40px;
}

.owl-single.dots-absolute .owl-dots .owl-dot span,
.owl-carousel.dots-absolute .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.5);
}

.owl-single .owl-dots,
.owl-carousel .owl-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.owl-single .owl-dots .owl-dot,
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  margin: 5px;
}

.owl-single .owl-dots .owl-dot span,
.owl-carousel .owl-dots .owl-dot span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.owl-single .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot.active span {
  background: #136ad5;
}

.owl-single.no-dots .owl-dots,
.owl-carousel.no-dots .owl-dots {
  display: none;
}

.owl-single.no-nav .owl-nav,
.owl-carousel.no-nav .owl-nav {
  display: none;
}

.testimonial {
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}

.testimonial .name {
  font-size: 18px;
  color: #000000;
}

.testimonial .img-wrap img {
  margin: 0 auto;
  width: 70px;
  border-radius: 50%;
}

.list-check li {
  display: block;
  padding-left: 30px;
  position: relative;
}

.list-check li:before {
  content: '\e5ca';
  font-family: 'icomoon';
  position: absolute;
  top: -0.3rem;
  font-size: 20px;
  left: 0;
}

.owl-single .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .owl-single .owl-nav {
    display: none;
  }
}

.owl-single .owl-nav .owl-prev,
.owl-single .owl-nav .owl-next {
  position: absolute;
  top: 0;
}

.owl-single .owl-nav .owl-prev span,
.owl-single .owl-nav .owl-next span {
  display: block;
  background: #ffffff;
  padding: 15px;
}

.owl-single .owl-nav .owl-prev {
  left: -30px;
}

.owl-single .owl-nav .owl-next {
  right: -30px;
}

.owl-single .owl-nav .owl-next>span {
  position: relative;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.ul-check li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.ul-check li:before {
  position: absolute;
  left: 0;
  top: -0.2rem;
  content: '\e5ca';
  font-size: 20px;
  font-family: 'icomoon';
}

.ul-check.primary li:before {
  color: #ec661f;
}

.ul-check.white li:before {
  color: #ffffff;
}

.section-title {
  color: #000000;
  margin-bottom: 30px;
}

.site-footer {
  background: #323232;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 0;
}

@media (max-width: 400px) {
  .site-footer ul {
    width: 100%;
  }
}

.site-footer a {
  color: #ffffff;
  position: relative;
  display: inline-block;
}

.site-footer .btn:before {
  display: none;
}

.site-footer .widget {
  /* margin-bottom: 40px; */
  display: block;
  position: relative;
  z-index: 1;
}

.site-footer .widget h3 {
  font-size: 25px;
  color: #ffffff;
}

.site-footer .widget .links {
  width: 150px;
}

.site-footer .widget .links li {}

.site-footer .widget .links li a {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}

.site-footer .widget .links li a:hover {
  color: #ffffff;
}

.site-footer .social li {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.site-footer .social li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  color: #000000;
  align-self: center;
}

.site-footer .social li a:before {
  display: none;
}

.site-footer .social li a>span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-footer .social li a:hover {
  background: #ec661f;
  color: #ffffff;
}

.site-footer .copyright {
  color: rgba(255, 255, 255, 0.5);
}

/* contact */
.display-6 {
  font-size: 28px;
}

/* courses */
/* .d-inline-block{
  background-color:#ED7D31 ;
} */
/* about */
.about {
  margin-top: 140px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.p {
  text-align: center;
  font-size: 20px;
  color: black;
  /* margin-top: 100px; */
}

@media (max-width: 400px) {
  .p {
    font-size: 17px;
  }
}

.about h2 {
  color: #ec661f;
  padding-top: 30px;
}

/* sidebar */
/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #11101d;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: 250px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #fff;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #fff;
}

.sidebar .bx-search:hover {
  background: #fff;
  color: #11101d;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #11101d;
}

.sidebar li a:hover {
  background: #fff;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #11101d;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

/* slider */
.slider-image {
  width: 100%;
  height: 65vh;
  align-self: center;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width: 400px) {
  .slider-image {
    height: 30vh;
    margin-top: 30px;
  }
}

@media (max-width: 390px) {
  .slider-image {
    height: 30vh;
    margin-top: 30px;
  }
}

.slider-item {
  position: relative;
  text-align: center;
  margin-top: 70px;

  /* height: 520px; */
}

@media (max-width: 400px) {
  .slider-item {
    /* margin-top: 10px; */

    height: 300px;
  }
}

/* slider text */
/* .slider-TextItem {
  background-color: white;
} */
.slider-TextItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 2vh;
  margin-left: 5%;
  margin-right: auto;
  width: 90%;
}

@media (max-width: 400px) {
  .slider-TextItem {
    margin-left: 0;
    height: 300px;
  }

  .slider-TextItem .image-overlay {
    height: 500px !important;
  }

  .slider-TextItem .image-overlay .para {
    margin: auto;
  }
}

@media (max-width: 1920px) {
  .slider-TextItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 60px;
    margin-bottom: 20px;
    margin-right: auto;
    width: 100%;
    margin-left: 0;
    background-color: transparent !important;
  }

  .slider-TextItem .image-overlay {
    width: 90%;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
  }

  .slider-TextItem .image-overlay .image-para {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.center-container {
  display: flex;
  justify-content: center;
}

.slider-TextItem .image-overlay {
  width: 100%;
  height: 250px;
  align-self: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 8px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
  justify-content: center;
}

/* slider 2 */
.slider-image2 {
  width: 100%;
  height: 75vh;
  align-self: center;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: -10px;
}

@media (max-width: 400px) {
  .slider-image2 {
    height: 40vh;
    margin-top: 30px;
  }
}

/* .slider-item2 {
  position: relative;
  text-align: center;
  margin-top: 100px;
  height: 520px;
} */
@media (max-width: 400px) {
  .slider-item2 {
    margin-top: 10px;

    height: 300px;
  }
}

.slider-item2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  margin-left: 5%;
  margin-right: auto;
  width: 100%;
}

@media (max-width: 400px) {
  .slider-item2 {
    margin-left: 0;
    height: 300px;
  }
}

@media (max-width: 1920px) {
  .slider-item2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 60px;
    margin-bottom: 20px;
    margin-right: auto;
    width: 100%;
    margin-left: 0;
    background-color: transparent !important;
  }

  .slider-item2 .image-overlay2 {
    width: 80%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
  }

  .slider-item2 .image-overlay2 .image-para2 {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.center-container {
  display: flex;
  justify-content: center;
}

.slider-item2 .image-overlay2 {
  width: 70%;
  height: auto;
  align-self: center !important;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center !important;
  position: absolute;
}

.image-overlay2 .image-text2 {
  text-align: center;
  margin-top: 30px;
  color: white;
  font-size: 44px;
}

@media (max-width: 400px) {
  .image-overlay2 .image-text2 {
    font-size: 23px;
  }
}

.image-heading2 {
  text-align: center;
  margin-top: 0px;
  color: black !important;
  font-size: 35px;
}

@media (max-width: 400px) {
  .image-heading2 {
    font-size: 20px;
  }
}

.image-overlay2 .image-para2 {
  text-align: center;
  color: white;
  width: 100%;
  font-size: 22px;
  /*  */
}

@media (max-width: 400px) {
  .image-overlay2 .image-para2 {
    font-size: 16px;
  }
}

@media (max-width: 1545px) {
  .image-overlay2 .image-para2 {
    width: 80%;
    padding: 0 20px;
  }
}

@media (max-width: 1920px) {
  .image-overlay2 .image-para2 {
    width: 80%;
    padding: 0 20px;
  }
}

.image-heading2 {
  color: white;
  text-align: center;
}

.image-heading22 {
  color: white;
  text-align: center;
}

@media (max-width: 400px) {
  .image-heading22 {
    font-size: 16px;
  }
}

.para2 {
  color: white;
  text-align: center;
  font-size: 25px;
}

@media (max-width: 400px) {
  .para2 {
    font-size: 16px;
    width: 90%;
  }
}

/* imagetext */
.image-overlay .image-text {
  text-align: center;
  color: black;
  font-size: 30px;
  margin-top: 2vh;
}

@media (max-width: 768px) {
  .image-overlay .image-text {
    font-size: 5vw;
  }
}

@media (max-width: 400px) {
  .image-overlay .image-text {
    font-size: 14px;
  }
}

.image-heading {
  text-align: center;
  margin-top: 0px;
  color: black;
  font-size: 35px;
}

@media (max-width: 400px) {
  .image-heading {
    font-size: 20px;
  }
}

.image-overlay .image-para {
  text-align: center;
  color: black;
  width: 80%;
  font-size: 22px;
  /*  */
}

@media (max-width: 400px) {
  .image-overlay .image-para {
    font-size: 16px;
  }
}

@media (max-width: 1545px) {
  .image-overlay .image-para {
    width: 80%;
    padding: 0 20px;
  }
}

@media (max-width: 1920px) {
  .image-overlay .image-para {
    width: 80%;
    padding: 0 20px;
  }
}

.image-heading2 {
  color: black;
  text-align: center;
}

@media (max-width: 400px) {
  .image-heading2 {
    font-size: 16px;
  }
}

.para {
  color: black;
  text-align: center;
  font-size: 25px;
}

@media (max-width: 400px) {
  .para {
    font-size: 16px;
    width: 90%;
  }
}

/* datePicker */
.date-picker-container {
  background-color: #fd9800;
}

/* browse packages */
/* .col-lg-6 {
  height: 130px;
} */
/* offer */
.offer {
  border-radius: 20px;
  height: 50px;
  margin-top: -180px;
}

/* package*/
.card {
  -webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.1);
}

/* carsection */
.carSection {
  background-color: mistyrose;
}

/* cardBody */
.card-body {
  display: inline-block;
}

.card {
  border-radius: 13px;
}

/* .card-body:hover {
  background-color: #ed7d31;
  color: white;
  cursor: pointer;
} */
/*  G2- card header*/
.card-header {
  background-color: white;
  border-color: white;
}

.csm {
  background: #323232;
}

.csm h2 {
  text-align: center;
  padding-top: 20px;
}

.csm p {
  padding: 0 20px;
}

/* counter */
.btn-dark {
  height: 50px;
  border-radius: 7px;
}

.btn-dark h2 {
  margin-top: -15px;
  color: white;
}

.whitePlaceholder::placeholder {
  font-size: 12px;
  margin-bottom: 7px;
}

.whitePlaceholder {
  border: none;
  outline: none;
  background-color: transparent;
  height: 34px;
  width: 80%;
}

.whitePlaceholder:focus {
  background-color: transparent;
}

.outerInput:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.outerInput {
  display: flex;
  height: 34px;
  width: 197px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.508);
  overflow: hidden;
  backdrop-filter: blur(8px);
  border-radius: 30px;
}

.innerBtn {
  width: 28px;
  height: 28px;
  background-color: rgb(228, 228, 228);
  border: none;
  border-radius: 50%;
  margin: 3px 3px 0 0;
  outline: none;
}

.innerBtn:hover {
  background-color: rgb(255, 255, 255);
}

.innerBtn:focus {
  outline: none;
}

@media (max-width: 400px) {
  .whitePlaceholder {
    width: 70%;
  }

  .whitePlaceholder .btn {
    margin-right: 90px;
  }
}

.ownPnn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Admin Panel Sidebar */

/*  */
.bi-x-lg {
  color: grey;
}

.fixAnc {
  width: 250px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: start;
  text-align: start;
}

.fixAncc {
  width: 250px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: end;
  text-align: end;
}

/************************ own */
.cardContainer {
  width: 95%;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 20px;
  position: relative;
  margin: -20px 20px 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.cardChild {
  min-height: 200px;
  padding: 15px;
  border: 1px solid #ffc096;
  position: relative;
  margin-right: -1px;
}

.cardChild .cardHeading {
  min-height: 50px;
}

.cardChild .cardContent {
  height: 100px;
}

.cardChild::before {
  content: '';
  position: absolute;
  top: 18px;
  left: -9px;
  width: 18px;
  height: 18px;
  background-color: #8aa6de;
  border-radius: 50%;
}

.cardChild:nth-child(even) {
  border-top: transparent;
}

.cardChild:nth-child(odd) {
  border-bottom: transparent;
}

.cardHeading h2 {
  font-size: 17px;
}

.cardContent h5 {
  font-size: 15px;
}

.cardCar {
  width: 20px;
  height: 37px;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 2;
}

@media (max-width: 767px) {
  .cardCar {
    display: none;
  }

  .cardChild {
    border: none;
    min-height: 180px;
  }

  .cardContainer {
    padding: 100px 40px;
  }
}

.contBtn {
  text-align: center;
  margin-top: 30px;
}

.contBtn .btn-outline-primary {
  font-size: 17px;
  -webkit-box-shadow: 10px 10px 20px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 20px 7px rgba(0, 0, 0, 0.1);
}

@media (max-width: 400px) {
  .contBtn .btn-outline-primary {
    font-size: 13px;
    text-align: center;
  }
}

/* bde package component */
.featureBackground {
  background-color: white;
  display: flex;
  width: 95%;
  height: auto;
  margin: 9px 40px;
  padding: 40px 10px 5px 10px;
}

.featureBackground h3 {
  color: #ec661f;
}

.featureBackground h6 {
  line-height: 27px;
}

.packageDescription {
  text-align: center;
  line-height: 40px;
}

.learningButton {
  border: none;
  background-color: white;
  font-size: 19px;
}

/* detail modal */
.fieldStyle {}

.fieldStyle label {
  text-align: start;
  font-weight: bold;
}

.fieldStyle input {
  width: 75%;
}

.fieldStyle b {
  margin-left: 10px;
}

/* topBar */
.topBar {
  background-color: white;
  height: 30px;
  text-align: center;
  padding-top: 5px;
}

/* main background */
.main {
  background-color: rgb(255, 255, 255);
  margin-top: 240px;
  height: auto !important;
}

.homeContainer {
  background-color: #f5eada;
  width: 93%;
  margin: auto;
  height: auto !important;
}

/* @media (max-width: 1920px) {
  .homeContainer {
    width: 91%;
  }
} */
/* package */
.packageBtn {
  height: 40px;
  padding: 5px 20px;
  margin: 7px 0;
  -webkit-box-shadow: 10px 10px 20px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 20px 7px rgba(0, 0, 0, 0.1);
  width: 60%;
  border: 1px solid #ec661f;
  border-radius: 20px;
  background-color: white;
  font-size: 17px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.packageBtn:hover {
  background-color: #ec661f;
  color: white;
}

.packageBtn:active {
  background-color: #523a2e;
  color: white;
}

.displayS {
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .slider-image {
    width: 100%;
  }

  .image-para {
    width: 40%;
  }
}

.untree_co {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 68vh;
}

.untree_co_Text {
  background: rgba(0, 0, 0, 0.6);
  height: 250px;
  width: 70%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto;
  /* Add this to center the container horizontally */
}

.untree_co_Text h2 {
  font-size: 34px;
  color: #ec661f;
}

.untree_co_Text h5 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

@media (max-width: 400px) {
  .untree_co h3 {
    font-size: 18px;
  }

  .untree_co p {
    font-size: 16px;
  }

  .untree_co h2 {
    font-size: 22px;
  }

  .untree_co h5 {
    font-size: 16px;
  }

  .untree_co_Text {
    background: rgba(0, 0, 0, 0.6);
    height: 250px;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0 auto;
    /* Add this to center the container horizontally */
  }

  .untree_co_Text.btn-primary {
    font-size: 15px;
  }
}

@media (max-width: 991.98px) {
  .untree_co {
    padding: 50px 0;
    height: 50vh;
  }
}

.untree_co .heading h3 {
  font-size: 35px;
  color: #000000;
}

@media (max-width: 991.98px) {
  .untree_co .heading {
    font-size: 20px;
  }
}

.untree_co .heading strong {
  font-weight: 700;
}

.borderDiv {
  width: 100%;
  height: 50px;
  background-color: rgb(37, 90, 37);
  margin: 10px auto;
}

.bgImg img {
  width: 100%;
  padding-top: 80px;
  height: 70vh;
}

@media (max-width: 400px) {
  .bgImg img {
    height: 30vh;
  }
}

@media (max-width: 1920px) {
  .bgImg img {
    width: 100%;
    margin: 0;
  }
}

.custom1 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.boxPackage {
  margin-top: 35px;
}

@media (max-width: 400px) {
  .boxPackage {
    margin-top: -100px;
    height: auto;
  }
}

.packageName {
  color: black;
}

.packageName:hover {
  color: #ec661f;
}

.container-fluid {
  width: 90%;
}

.topImage img {
  margin-top: 100px;
  width: 100%;
  height: 40vh;
  position: relative;
}

.pricingSection {
  margin-top: 100px;
}

/* aboutInstructor */
.aboutInstructor {
  margin-top: 100px;
}

.p {
  text-align: center;
  font-size: 20px;
}

@media (max-width: 400px) {
  .p {
    font-size: 17px;
  }
}

.aboutInstructor h2 {
  color: #ec661f;
  padding-top: 30px;
}

.logInBox {
  margin-top: 90px;
  z-index: 2;
}

.studentPanel {
  background-color: white;
  padding-top: 90px;
}

.instructorList {
  background-color: white;
  /* margin-top: 90px; */
}

.courseList {
  margin-top: 80px;
}

.course {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #ffffff;
  border-radius: 15px;
  text-align: center;
  height: 200px;
}

.course .course-body {
  padding: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.course .course-body h2 {
  font-size: 22px;
}

.course-body h5 {
  font-size: 17px;
  color: #7b7b7b;
}

.course-body .rounded {
  border-top: 2px solid #ec661f;
}

@media (max-width: 400px) {
  .course-body {
    margin-top: 10px;
  }

  .course-body h2 {
    font-size: 10px;
  }

  .course-body h5 {
    font-size: 14px;
  }
}

.box {
  cursor: pointer;
  user-select: none;
}

.highlighted {
  outline: 2px solid #ec661f;
  border-radius: 20px;
}

.highlighted-text {
  color: #ec661f;
}

.discounted {
  text-decoration: line-through;
  color: black !important;
  font-size: 20px !important;
}

.discount {
  font-size: 44px;
  color: #ec661f;
}

.save {
  color: black;
  font-size: 20px;
}

.instructorPanel {}

.instructorIcon {
  font-size: 50px;
}

.instructorIcon:hover {
  color: white;
}

.studentList {
  padding-top: 100px;
  padding-bottom: 90px;
}

/* learn to drive */
.learn {
  display: flex;
  padding-top: 70px;
}

.learnSection {
  width: 60%;
}

.pointSection {
  display: flex;
  margin-top: 10px;
  padding-bottom: 20px;
}

.keyPoints p {
  color: black;
}

.imgLearnToDrive img {
  margin-top: 60px;
  /* height: 70vh; */
}

@media (max-width: 1290px) {
  .learn {
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .learn {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .learn .learnSection {
    width: 90%;
  }

  .learn .pointSection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .learnImg img {
    padding: 10px 0;
  }

  .imgLearnToDrive img {
    height: 28vh;
    width: 100%;
  }
}

/* obtainLicense */
@media (max-width: 400px) {
  .lessonContent h3 {
    font-size: 23px;
    text-align: justify;
  }

  .lessonContent p {
    text-align: justify;
    margin: 0 !important;
  }

  .bookBox h3 {
    padding: 0 10px;
  }
}

/* .getPrices {
  margin-top: -150px;
  display: flex;
  justify-content: space-between;
} */
.customizeHeading {
  background-color: #ec661f;
  color: white;
  text-align: center;
  margin-top: 10px !important;
  padding-top: 100px;
  border-radius: 50%;
}

.slideContainer {
  height: auto;
}

.slideContent {
  /* width: 100%; */
  margin-top: 20px;
}

.slideImages {
  margin-top: 30px;
  z-index: 3;
  display: flex;
  justify-content: space-around !important;
}

.slideImages img {
  height: 60vh;
  align-content: center;
}

.slideImages h5,
.slideImages p {
  color: black;
  font-size: 30px;
  margin: 0;
}

.gray-stripe {
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: 470px;
  /* Adjust height as needed */
  background-color: #f3f3f3;
  /* Gray background color */
  z-index: -1;
  /* Place behind other content */
}

@media (max-width: 1470px) {
  .slideContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .slideImages img {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .slideImages .container {
    flex-direction: column;
    /* Change flex direction to column on smaller screens */
  }

  .slideContent {
    padding-left: 0;
    /* Remove padding for content on smaller screens */
  }
}

@media (max-width: 400px) {
  .slideImages {
    width: 100%;
    justify-content: center;
  }

  .slideImages .gray-stripe {
    width: 100%;
    padding: 0;
  }

  .slideImages img {
    height: 400px;
  }

  .slideContent h5 {
    font-size: 20px;
  }

  .slideContent p {
    font-size: 18px;
  }
}

@media (max-width: 1250px) {
  .slideContainer {
    flex-wrap: wrap;
  }

  .slideImages img {
    display: flex;
    flex-wrap: wrap;
  }
}

.aboutLearnToDrive {
  margin-top: 70px;
}

.aboutLearnToDrive {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 140px;
  padding-bottom: 50px;
}

.aboutLearnToDrive h2 {
  margin-top: 60px;
}

@media (max-width: 400px) {
  .aboutLearnToDrive {}

  .aboutLearnToDrive h2 {
    font-size: 25px;
    text-align: center;
  }

  .aboutLearnToDrive p {
    font-size: 18px;
    text-align: justify;
  }
}

.Postal {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #ec661f !important;
  width: 60%;
  /* Adjust width */
  height: 45px;
  margin-top: 70px;
}

.postalBtn {
  margin-top: 60px;
  height: 45px;
  font-size: 17px;
  /* Adjust margin as needed */
}

.postalBtn .btn {
  height: 60px !important;
}

@media (max-width: 400px) {
  .postalBtn {
    height: 30px;
    font-size: 15px;
  }
}

.pickPostal {
  border: none;
  width: 70%;
  /* Adjust width as needed */
}

.pickPostal::placeholder {
  font-size: 22px;
  color: black;
}

.geoIcon i {
  font-size: 30px;
  color: black;
}

/*  */
.date {
  width: 120px;
  padding-bottom: 20px;
}

.date h5 {
  color: black;
}

.date h6 {
  color: black;
}

.timeSlot {
  border: 1px solid black;
  font-size: 18px;
  color: black;
  border-radius: 10px;
  height: 50px;
  padding-top: 5px;
  margin-left: 10px;
  text-align: center;
  background-color: white;
}

/*  */
.scheduleModal {
  max-width: 80%;
  background-color: #8aa6de;
  color: black;
}

.sessionDate {
  width: 350px !important;
}

.TheoryTest {
  background-color: white;
  margin-top: 60px;
  /* padding-top: 40px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 40px 30px;
}

.TheoryTest p {
  color: black;
  font-size: 18px;
}

.TheoryTest h3 {
  color: #ec661f;
}

.bookBox {
  margin-top: 140px;
  text-align: center;
  background: #4b6bc6;
  color: white;
  padding: 60px 0;
}

.btnPrice {
  margin-top: 30px;
  background-color: white !important;
  color: #4b6bc6 !important;
}

.refesherLesson {
  width: 90%;
  margin: auto;
}

.refesherLesson h2 {
  padding-top: 50px;
  font-size: 40px;
}

@media (max-width: 400px) {
  .refesherLesson {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .refesherLesson .d-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .refesherLesson {
    display: flex;
    flex-wrap: wrap;
  }

  .d-flex .Postal {
    width: 100%;
  }

  .refesherLesson h2 {
    font-size: 28px;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .refesherLesson h2 {
    font-size: 28px;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .refesherLesson h4 {
    text-align: center;
  }

  .refesherLesson .PriceG {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.img-wrap .img-fluid {
  padding: 0;
  margin: 0 !important;
  height: 45vh;
}

@media (max-width: 400px) {
  .img-wrap .img-fluid {
    height: 22vh;
    margin-top: 50px !important;
    object-fit: auto;
  }
}

.PriceG {
  align-self: center !important;
  font-size: 15px !important;
}

.lessonContent {
  margin-bottom: 90px;
}

.lessonContent h3 {
  margin-top: 50px;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/*  */
@media (max-width: 400px) {
  .Faq {
    width: 100%;
    /* background-color: #005f75; */
    margin: 0;
    padding: 0;
  }

  .Faq h5 {
    text-align: justify;
  }

  .Faq h2 {
    font-size: 27px;
  }
}

.username {
  font-weight: bold;
  font-size: 13px;
}

.text {
  color: #555;
  font-size: 11px;
}

.textSession {
  color: #555;
  font-size: 14px;
}

.date {
  color: #999;
  font-size: 12px;
}

/*  */
.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prices-list {
  /* margin-right: 20px; */
}

.payment-form {
  padding: 20px;
  border-radius: 8px;
  margin-top: 50px;
}

.form-group {
  margin-bottom: 15px;
}

/* label {
  font-weight: bold;
} */

.error {
  color: red;
}

.expiry-cvv {
  display: flex;
  justify-content: space-between;
}

.stripe-btn {
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.stripe-btn:hover {
  background-color: #005f75;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/****************Admin*******************/

.Sideheader a {
  text-decoration: none;
}

.AdminMContainer {
  font-family: 'Work Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f3f3f3;
  transition: 300ms;
}

/*Link to JS*/
.Sideheader {
  width: 6%;
}

/*Link to JS*/
.Sideheader1 {
  width: 18%;
}

.btn2 {
  width: 100%;
  height: 10vh;
  background-color: transparent;
  color: #d7d7d7;
  border: none;
  font-size: 22px;
  outline: none;
  padding: 0 20px;
  background-color: #313131;
  cursor: pointer;
  margin-bottom: 10px;
}

.btn2:hover {
  background-color: #ec661f;
  color: white;
}

.btn2:focus {
  outline: none;
}

.Sideheader ul {
  width: 100%;
  text-decoration: none;
  list-style: none;
  padding: 0;
}

.Sideheader ul li {
  margin: 5px 6px;
}

.anchor {
  overflow: hidden;
  border-radius: 10px;
  justify-content: center;
  background-color: #d7d7d7;
  display: flex;
  width: 15vw;
  transition: 300ms;
  width: 100%;
  height: 10vh;
  align-items: center;
  padding: 0 10%;
  color: #444;
  outline: none;
}

.anchor:hover {
  color: #ffffff;
  background-color: #ec661f;
}

.anchor:hover .name1 {
  color: #ffffff;
}

.anchor>i {
  font-size: 20px;
}

.btnAnchor {
  overflow: hidden;
  border-radius: 10px;
  justify-content: center;
  background-color: #d7d7d7;
  display: flex;

  transition: 300ms;
  width: 200px;
  height: 55px;
  align-items: center;
  /* padding: 0 10%;*/
  color: #444;
  outline: none;
  margin-top: 30px;
}

.btnAnchor:hover {
  color: #ffffff;
  background-color: #18b259;
}

.btnAnchor:hover .name1 {
  color: #ffffff;
}

.btnAnchor>i {
  font-size: 20px;
}

.lnkAnchor {
  overflow: hidden;
  border-radius: 10px;
  justify-content: center;
  background-color: #d7d7d7;
  display: flex;
  transition: 300ms;
  width: 200px;
  height: 55px;
  align-items: center;
  /*padding: 0 10%;*/
  color: #444;
  outline: none;
  margin-top: 30px;
  border: 2px solid #000;
}

.lnkAnchor:hover {
  color: #ffffff;
  background-color: #18b259;
}

.lnkAnchor:hover .name1 {
  color: #ffffff;
}

.btnAnchor>i {
  font-size: 20px;
}

/*Link to JS*/
.name {
  width: 60%;
  display: none;
  margin: 0 5px;
}

/*Link to JS*/
.name1 {
  width: 100%;
  margin: 0 0 0 15px;
  display: block;
  font-weight: 400;
  transition: 300ms;
}

/*Link to JS*/
.bodyContainer {
  width: 94%;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'Work Sans', sans-serif;
  background-attachment: fixed;
  background-size: cover;
  padding: 20px;
}

/*Link to JS*/
.bodyContainer1 {
  width: 82%;
  min-height: 100vh;
  background-color: white;
  padding: 20px;
}

@media (max-width: 1000px) {

  /*Link to JS*/
  .Sideheader {
    width: 8%;
  }

  /*Link to JS*/
  .Sideheader1 {
    width: 22%;
  }

  /*Link to JS*/
  .bodyContainer {
    width: 92%;
  }

  /*Link to JS*/
  .bodyContainer1 {
    width: 78%;
  }
}

@media (max-width: 830px) {

  /*Link to JS*/
  .Sideheader {
    width: 10%;
  }

  /*Link to JS*/
  .Sideheader1 {
    width: 26%;
  }

  /*Link to JS*/
  .bodyContainer {
    width: 90%;
  }

  /*Link to JS*/
  .bodyContainer1 {
    width: 74%;
  }
}

@media (max-width: 600px) {

  /*Link to JS*/
  .Sideheader {
    width: 14%;
  }

  /*Link to JS*/
  .Sideheader1 {
    width: 32%;
  }

  /*Link to JS*/
  .bodyContainer {
    width: 86%;
  }

  /*Link to JS*/
  .bodyContainer1 {
    width: 68%;
  }

  .bt-group {
    flex-flow: column wrap;
  }

  .leftDiv {
    margin-bottom: 20px;
  }

  .rightDiv {
    margin-bottom: 20px;
  }
}

@media (max-width: 450px) {

  /*Link to JS*/
  .Sideheader {
    width: 18%;
  }

  /*Link to JS*/
  .Sideheader1 {
    width: 40%;
  }

  /*Link to JS*/
  .bodyContainer {
    width: 82%;
  }

  /*Link to JS*/
  .bodyContainer1 {
    width: 60%;
  }
}

.Adminlogo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Adminlogo img {
  width: 80%;
}

.AdminContainer h2 {
  margin-bottom: 1px;
}

.AdminContainer input {
  margin: 0 0 20px 0;
}

.AdminContainer input[type='text'] {
  margin: 0 0 20px 0;
  height: 32px;
}

.AdminContainer input[type='submit'] {
  margin: 10px 0 15px 0;
  padding: 12px 25px;
  border-radius: 0px;
}

.AdminContainer .btn {
  margin: 20px 0;
}

.AdminContainer label {
  margin: 0 0 5px 0;
  color: #808080;
}

.EmptyContainer {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alignme {
  padding: 0;
  width: 25%;
  display: flex;
  justify-content: end;
}

.text-yo {
  font-size: 15px;
}

.AdminDeleteContainer {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubDelete {
  width: 50%;
  min-height: 45vh;
  background-color: rgb(221 221 221 / 0.74);
  padding: 30px;
  border-radius: 8px;
  /*margin:10% 0 0 0;*/
}

.SubDelete dt {
  color: #515151;
}

.SubDelete dd {
  font-size: 18px;
  color: #757575;
}

.imgdiv1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.imgdiv1 img {
  width: 100%;
}

.imgdiv2 {
  width: 180px;
  height: 150px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.imgdiv2 img {
  height: 100%;
}

.EmptyContainer1 {
  width: 100%;
  height: 110px;
}

.fixHeight {
  max-height: 50px;
}

.container1 {
  width: 90%;
  margin: 0 auto;
}

.fm-group {
  width: 100%;
  height: 100%;
}

.fm-group .lbl {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #444;
}

.fm-group .fm-control {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
}

.bt-group {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.leftDiv {
  width: 30%;
  height: 50px;
}

.rightDiv {
  width: 30%;
  height: 50px;
}

.fieldStyle {
  display: flex;
}

.fieldStyle label {
  width: 20%;
  text-align: start;
}

.fieldStyle input {
  width: 75%;
}

.fieldStyle b {
  margin-left: 10px;
}

/* span {
  color: red;
} */
.modal-footer .detailImageBoxContainer {
  width: 100%;
  display: flex;

  flex-wrap: wrap;

  margin-left: 10px;
}

.modal-footer .detailImageBoxContainer img {
  height: 90%;
  width: 90%;
}

.modal-footer .detailImageBoxContainer .detailImageBox {
  height: 130px;
  width: 130px;
  border: #737373 solid 1px;
  margin-left: 10px;
  margin-top: 10px;
}

.modal-footer .detailImageBoxContainer .detailImageBox:hover {
  opacity: 0.8;
}

.actionButtons {
  display: flex;
  flex-wrap: wrap;
}

.lnkButton {
  background-color: #ec661f;
  color: white !important;
  font-size: 20px;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
}

.middleArea {
  margin-top: 80px;
}

/*  */
.testCategory {
  display: flex;
  justify-content: space-evenly;
  margin-top: 90px;
}

.categoryBox {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 150px;
}

.categoryBox h2 {
  font-size: 30px;
  /* Adjust font size */
  padding-right: 20px;
  /* Add right padding to create space between text and image */
}

.categoryname {
  flex-grow: 1;
  /* Allow the text to expand to fill available space */
}

.testImg {
  flex-shrink: 0;
  /* Prevent the image from shrinking */
  margin-left: auto;
  /* Push the image to the right */
}

.testImage {
  width: 100%;
  /* Ensure the image fills its container */
  max-width: 150px;
  /* Adjust maximum width of the image as needed */
  height: auto;
  /* Maintain aspect ratio */
  background-color: #1776ea;
}

.testDescription {
  padding: 20px;
  font-size: 20px;
  text-align: justify;
}

.testFeatureDiv {
  width: 27%;
}

.testFeature {
  margin-top: 50px;
}

@media (max-width: 400px) {
  .testCategory {
    display: flex;
    flex-wrap: wrap;
  }

  .testCategory .categoryBox {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    /* background-color: #005f75; */
  }

  .testCategory .categoryname {
    width: 100%;
  }

  .testCategory .testImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testFeature {
    display: flex;
    flex-wrap: wrap;
  }

  .testFeatureDiv {
    width: 100%;
  }
}

.testFeatureIcon {
  background-color: #ec661f;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  text-align: center;
}

.testFeatureIcon i {
  font-size: 30px;
  color: white;
  padding-top: 10px;
}

.testContent {
  padding: 10px 20px 0 0;
}

.testContent h5 {
  color: black;
}

.testContent p {
  color: black;
}

.testAbout {
  margin-top: 60px;
}

.testAbout h5 {
  color: black;
}

/* test module */
/* body {
  background-color: rgb(255, 229, 222);
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
} */

.QContainer {
  max-width: 75%;
  /* background-color: rgb(243, 243, 243); */
  margin: 10px auto 0 auto;
  border-radius: 8px;
  overflow: hidden;
  height: auto;
  /*  */
}

.mainBox {
  margin: 0 auto;
  /* Optionally, you can set a max-width if needed */
  max-width: 1500px;
  /* Adjust as needed */
}

@media (max-width: 400px) {
  .mainBox {
    display: flex;
    flex-wrap: wrap;
  }

  .mainBox .testBox {
    width: 100%;

    margin: 0 10px;
  }
}

.row1 {
  width: 100%;
  min-height: auto;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  padding: 65px 0;
}

.Item {
  height: 75vh;
  width: 900px;
  background-color: white;
  margin: 25px auto;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
}

.itemTest {
  height: auto;
}

.ImageContainer1 {
  width: 35%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 10px;
  margin-top: 60px;
  /* background-color: rgb(230, 230, 230); */
}

.ImageContainer1 img {
  width: 100%;
  height: 200px;
  background-size: cover;
}

.contentContaner5 {
  width: 72%;
  height: 100%;
  /* height: auto; */
  padding: 20px 10px;
}

.contentContaner5>p {
  font-size: 25px;
  text-align: left;
  color: black;
}

.options {
  /* display: flex;
  justify-content: space-evenly !important; */
  text-align: left;
  border-radius: 8px;
  padding: 17px 15px 0 10px;
  background-color: rgb(215, 215, 215);
  /* width: 100%; */
  font-size: 18px;
  min-height: 15px;
  margin-top: 20px;
  cursor: pointer;
  transition: 200ms;
  color: black;
  /* padding-top: 19px; */
}

.options:hover {
  background-color: rgb(184, 184, 184);
}

.contentContainer5 {
  padding: 15px;

  width: 100%;
}

.contentContainer5 h5 {
  color: black;
  text-align: justify;
}

.contentContaner5>label>p {}

.QContainer input[type='radio'] {
  display: none;
}

input[type='radio']:checked+label .options {
  background-color: rgb(230, 126, 9);
}

.buttonContainer {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

/* .summaryBox {
  background-color: #8aa6de;
} */
.testBox {
  background-color: white;
  width: 30%;
  /* Adjust as needed */
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  /* padding: 10px;  */
  box-sizing: border-box;
  border-radius: 20px;
  /* Include padding in width calculation */
}

.testBox h4 {
  padding-top: 1px;
}

.tinyBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  gap: 5px;
  /* Add spacing between items */
}

.tinyBox h5 {
  background-color: rgb(211, 210, 210);
  width: 30px;
  height: 30px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 30px;
  color: black;
}

.coin .correct::marker {
  color: #8df4a9;
}

.coin .incorrect::marker {
  color: #ff7c7c;
}

.locationModal {
  max-width: 900px;
}

.exam {
  margin: auto;
  margin-top: 90px;
  width: 75%;
}

.examImg img {
  max-width: calc(100% + 16px);
  /* Adjusting for Bootstrap's default padding */

  height: auto;
}

@media (max-width: 992px) {
  .examImg {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .card-body {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .exam {
    width: 90%;
  }

  .exam h2 {
    text-align: center;
    margin: 0;
  }

  .examImg {
    display: flex;
    justify-content: center;
  }

  .card-body .col-lg-6 {
    width: 100%;
    padding: 0;
    text-align: justify;
  }

  .card-body .col-lg-6 .card-title {
    width: 100%;
    padding-top: 15px;
    text-align: center;
  }
}

/* Modals */
.addModal {
  max-width: 850px;
}

.detailModal {
  max-width: 850px;
}

.editModal {
  max-width: 850px;
}

/*  */
.optionArea {
  width: 100px !important;
}

.selected {
  background-color: #e6e6e6;
  color: #333;
  font-weight: bold;
  border-radius: 15px;
  height: 30px;
}

/* result */
.resultCard {
  width: 90%;
  margin: auto;
}

.resultCardHeading {
  text-align: center;
  font-size: 25px;
  color: black;
}

/*  */
.modalPay {
  max-width: 800px;
}

/*  */
.aboutInstructor {
  padding-top: 60px;
}

.aboutInstructor .untree_co-section .feature h1 {
  padding-top: 40px;
}

.aboutInstructor .untree_co-section .feature p {
  font-size: 23px;
}

.aboutInstructor .untree_co-section .feature h2 {
  text-align: left;
}

.instructorP {
  align-self: center !important;

  margin: auto;
}

.priceHeading {
  background-color: #ec661f;
  color: white;
  height: 120px;
}

.priceHeading h3 {
  color: white;
}

.priceHeading h5 {
  color: white;
  font-size: 20px;
}

.pBody:hover {
  color: black;
}

.priceP {
  font-size: 40px;
  color: #ec661f;
  padding-top: 15px;
}

.descriptionP {
  text-align: left !important;
  font-size: 18px;
  line-height: 50px;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #ec661f;
  color: #ffffff;
  border-radius: 5px;
  align-self: center;
}

.input-File {
  display: none;
}

/*  */

.contactBox {
  background-color: rgba(236,
      102,
      31,
      0.7);
  /* Set background color with opacity */
  height: 50vh;
  text-align: left;
  margin-top: 90px !important;
  border-radius: 20px;
}

.btnApply {
  background-color: white;
  color: #ec661f;
  font-size: 15px;
}

/*  */
.insRegisterTable {
  width: 100%;
  margin: 0 !important;
}

/*  */

.resultCol.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultCol .label,
.card-text .value {
  flex: 1;
}

.resultCol .label {
  max-width: 250px;
  /* Adjust this value as needed */
  font-weight: bold;
}

.resultCol .value {
  flex-grow: 1;
}

.card.resultCard.pass {
  background-color: rgb(164, 211, 164);
  color: white;
}

.card.resultCard.fail {
  background-color: rgb(209, 127, 127);
  color: white;
}

/* navbar login */

.logoutButton {
  font-size: 20px;
  border: none;
}

.logoutButton i {
  border: transparent;
  font-size: 20px;
}

.welcomeUser {
  display: flex;
  /* align-items: center; */
}

.bellIcon {
  font-size: 25px;
}

.badge {
  font-size: 10px;
  top: 10px;
  right: 7px;
  color: white;
  position: absolute;
}

.userInitialCircle {
  width: 40px;
  height: 40px;
  background-color: #d9534f;
  /* Circle background color */
  color: white;
  /* Initial text color */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  /* Margin between the circle and bell icon */
}

.dropdownMenu {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  /* Adjust as necessary */
  position: absolute;
  right: 0;
  z-index: 1000;
}

/* .dropdownMenu button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
} */

.dropdownMenu button:hover {
  background-color: #f8f9fa;
}

.userDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userDropdown .userDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.userDropdown .userInitialCircle.large {
  width: 60px;
  height: 60px;
  font-size: 24px;
  margin-bottom: 10px;
}

.userDropdown .userEmail {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.userDropdown .greeting {
  font-size: 16px;
  font-weight: bold;
}

.userDropdown .dropdownItem {
  color: black;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.userDropdown .dropdownItem:hover {
  border-bottom: 1px solid #ec661f;
  /* background-color: #f1f1f1; */
  border-radius: 5px;
}

.customDropDowns {
  display: flex;
  justify-content: space-around;
}

.navLogIn {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
}

.logoutButton i {
  margin-right: 8px;
}

.logoutButton:hover {
  /* background-color: #f1f1f1; */
  border-radius: 5px;
}

/* log off modal */
.unique-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 9999;
  /* Ensure it appears above other elements */
  width: 300px;
  /* Adjust width as needed */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add shadow for depth */
}

.unique-modal-content {
  background-color: #fff;
  padding: 20px;
}

.unique-modal-content p {
  margin-bottom: 15px;
}

.unique-modal-content button {
  margin-right: 10px;
}

/* Hide modal by default */
.unique-modal {
  display: none;
}

/* Show modal when modalOpen state is true */
.modal-open {
  display: block;
}







/* //////////////////// */
.bookOnlineBox5 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  height: 720px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
}
.bookOnlineBox3 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 250px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
}

.HalfOne {
  width: 40%;
  height: 100%;
  padding: 30px 0 30px 30px;
  overflow: hidden;
}

.HalfOne img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.HalfTwo {
  width: 60%;
  padding: 35px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow-y :scroll ; */
}

.innerHalf {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formIn {
  width: 80%;
  height: 45px;
  margin-top: 20px;
  /* border: 1px solid #ccc ; */
  border: none;
  border-radius: 08px;
  padding: 0 20px;
  outline: none;
  background-color: #f0f0f0;
}

.btnGear {
  background-color: #ededed;
}

.btnGearActive {
  background-color: #F66F27;
  color: white;

}

.btnGearActive:hover {
  color: white;
}

.btnGear:hover {
  background-color: #f2874e;
  color: white;
}

.pricingOnM {
  width: 85%;
  position: relative;
}

.pricingBox {
  width: 100%;
  min-height: 100px;
  border-radius: 08px;
  border: 2px solid #EBEBEB;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  outline: none;
  color: gray;

}

.pricingBox:focus {
  outline: none;

}

.pricingBox h3 {
  color: gray;
}

.activePricingBox {
  width: 100%;
  min-height: 100px;
  border-radius: 08px;
  border: 2px solid #EC661F;
  background-color: #cccccc05;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  outline: none;
  /* color: #fff; */
  box-shadow: 0 2px 10px #F2874E86;

}

.activePricingBox:focus {
  outline: none;

}

.activePricingBox .highlight {
  /* color: #fff; */
  /* font-weight: bold; */
  text-decoration: none;
}

.activePricingBox h3 {
  /* color: white; */
}

.pricingBox:hover {
  background-color: #FFFAF3;
  border: 2px solid #D0D0D0;
  box-shadow: 0 2px 10px #00000021;

}

.highlight {
  color: #f2874e;
  /* font-weight: bold; */
  text-decoration: none;
}

.highlight1 {
  color: black;
  text-decoration: line-through;
}

.instSet {
  width: 80%;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.instSet h6 {
  /* font-size: 13px; */
  margin: 0;
}

.textI {
  /* font-size: 13px; */
  text-align: end !important;
  margin: 0;
}

.instSet p {
  font-size: 13px;
  margin: 0;
}

.chageA {
  margin-bottom: 8px;
  cursor: pointer;
  color: #832EFF;
}

.dropPack {
  width: 100%;
  display: flex;
  justify-content: center;
}

.floatDropDown {
  background-color: rgba(244, 244, 244, 0.6);
  width: 80%;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  position: absolute;
  top: 1px;
  backdrop-filter: blur(20px);
  z-index: 100;
  /* box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.08); */
}

.dropItem {
  width: 100%;
  cursor: pointer;
  display: flex;
  border: 1px solid #e7e7e7;
  margin: 4px 8px;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 8px;
}

.dropItem p {
  font-size: 13px;
  margin: 0;
}

.dropItem h6 {
  margin: 0;
}

.textEnd {
  text-align: end;
}

.btnBox {
  position: relative;
  width: 25px;
  height: 25px;
  border: none;
  background-color: #fff;
  outline: none;
  transition: 400ms;
  margin: 0 9px 5px 0;
  border-radius: 6px;
}

.btnBox:hover i {
  color: #fff;
}

.btnBox:hover {
  background-color: #FF5B5B;
}

.btnBox:focus {
  outline: none;
}

.refundMsg {
  width: 80%;
  background-color: #cccccc50;
  border-radius: 9px;
  min-height: 100px;
}
.refundMsg2 {
  width: 100%;
  background-color: #cccccc50;
  border-radius: 9px;
  min-height: 50px;
  margin: 10px auto ;
  
}

.timeTnt {
  width: 100%;
  height: 160px;
  overflow-y: scroll;
}

.timeTnt::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}

.timeTnt::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;


}

.timeBomb {
  width: 100%;
  cursor: pointer;
  display: flex;
  border: 2px solid #e7e7e7;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 20px;
  align-items: center;
}

.timeBomb:hover {

  background-color: #FFF4EE;

}

.activeTimeBomb {
  border: 2px solid #ec661f;
  box-shadow: 0 2px 10px #F2874E46;
  background-color: #FFE9DD;

}

.slideBtn {
  border: none;
  background-color: transparent;
  color: #717171;
  font-weight: 500;
  transform: translateY(35px);
  z-index: 200;
  padding: 2px;
}

.slideBtn:focus {
  outline: none;
}

.top-50 {
  top: 0;
}

.left-n0 {
  left: -10px;
}

.right-n0 {
  right: -10px;
}

.formGroup {
  text-align: start;

}

.mant {
  height: 27px;
  display: block;
  padding: 0 10px;
}

.formControl {
  width: 100%;
  height: 48px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 15px;
  margin: 0 0 0px 0;
  outline: none;

}

.formControl:focus {
  border: 1px solid #FF8442;
  outline: none;
}

.ownC {
  border: 1px solid lightgray;
  padding: 20px 15px;
  border-radius: 15px;
}

.CalA {
  color: #832EFF !important;
  font-weight: bold;
  cursor: pointer;
}

.calD {
  position: relative;
  width: 40%;
}

.onTop {
  z-index: 100;
  bottom: -30px;
  left: 0;
}




.toggle-switch {
  width: 32px;
  height: 20px;
  position: relative;
  -webkit-appearance: none;
  background-color: #D5D5D5;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch:checked {
  background-color: #9D53D6;
}

.toggle-switch::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-switch:checked::before {
  transform: translateX(12px);
}

.dFlex1{
  display: flex;
  align-items: center;
  /* height: 60px; */
  
}
.dFlex1 label{
  display: block;
  margin:4px 0 0 9px;

}
.btnHollow{
  border: 2px solid lightgray;
  height: 50px;
  width: 49%;
  border-radius: 5px;
  background-color: white;
  font-size: 17px;
  font-weight: 600;

}
.btnHollow:focus{
  outline: none;
  
}
.btnHollowActive{
  border: 2px solid #FF8442;
  height: 50px;
  width: 49%;
  border-radius: 5px;
  background-color: white;
  outline: none;
  font-size: 17px;
  font-weight: 600;
}
.btnHollowActive:focus{
  outline: none;
  
}
.termsBox{
  border:1px solid lightgray;
  width: 100%;
  padding: 17px 20px;
  border-radius: 8px;
  font-size: 17px;
  
}
.loadingPaper{
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingBox{
  width: 60px;
  height: 60px;
  border: 10px solid #F66F27;
  border-radius: 50%;
  border-top: 10px solid transparent;
  animation: loadingBox 1500ms infinite linear;
}
@keyframes loadingBox {
  0%{
    transform: rotate( 0turn);
  }
  100%{
    transform: rotate( 1turn);

  }
}

.ti-1{
  text-indent: 0.5in;
}
.font-s p{
  font-size: 16px;
}
.mainOL{
text-align: justify;
}
.mainOL li{
  margin-bottom: 25px;
}
.mainOL li ol{
  padding-left: 50px;
  margin-top: 20px;
}
.mainOL li ol li{
  margin-bottom: 8px;
}
.btn-back{
  border: 1px solid #8C8C8C;
  height: 35px;
  width:35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  z-index: 1000;
}
.btn-house{
  position: absolute;
  top:35px;
}
.invoice2{
  display: flex;
  height:100%;
  flex-direction: column;
  padding:70px 20px;
  align-items: start;
}
.invoice2 p{
  margin: 0;
  margin-bottom: 10px;
}
.invoice2 b{
  margin: 0;
  margin-bottom: 10px;
}